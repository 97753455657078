<template>
  <el-main>
    <page-title show-back-btn>
      <template slot="btn-inner">
        <debounce-button v-if="viewEdit()" type="primary" @click="submitForms">
          提交
        </debounce-button>
      </template>
    </page-title>
    <div class="partition-area">
      <div v-loading="loading.detail" class="partition-area">
        <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
          <ics-page-inner title="金融产品信息">
            <el-col v-if="$route.params.editMode !== 'add'" :span="12">
              <el-form-item label="金融产品编码">
                <p>{{ utils.isEffectiveCommon(appForm.productCode) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="productName" label="金融产品名称">
                <ics-item-inner :prop="appForm.productName" :format="utils.isEffectiveCommon">
                  <el-input v-model="appForm.productName" placeholder="请输入金融产品名称" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col />
            <el-col v-if="!viewEdit()" :span="12">
              <el-form-item label="隶属资方">
                <ics-item-inner :prop="appForm.capName" :format="utils.isEffectiveCommon" />
              </el-form-item>
            </el-col>
            <el-col v-if="viewEdit()" :span="12">
              <el-form-item prop="capId" label="隶属资方">
                <ics-item-inner :prop="appForm.capCode" :format="utils.isEffectiveCommon">
                  <el-select v-model="appForm.capCode" placeholder="请选择所属资金方" filterable @change="(val)=>companyChange(val)">
                    <el-option v-for="item in capList" :key="item.capCode" :label="`${item.capName}（${item.capCode}）`" :value="item.capCode" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="productFinancing" label="产品类型">
                <ics-item-inner :prop="appForm.productFinancing" :format="(val)=>utils.statusFormat(Number(val), 'productType')">
                  <el-select v-model="appForm.productFinancing" placeholder="请选择产品类型" filterable>
                    <el-option v-for="item in constants.productType" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="!viewEdit()" :span="12">
              <el-form-item label="产品模型">
                <ics-item-inner :prop="appForm.productTypeName" :format="utils.isEffectiveCommon" />
              </el-form-item>
            </el-col>
            <el-col v-if="viewEdit()" :span="12">
              <el-form-item prop="modelId" label="产品模型">
                <ics-item-inner :prop="appForm.modelId" :format="utils.isEffectiveCommon">
                  <el-select v-model="appForm.modelId" placeholder="请选择产品模型" filterable @change="(val)=>modelChange(val)">
                    <el-option v-for="item in modelList" :key="item.id" :label="`${item.modelName}（${item.modelCode}）`" :value="item.id" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="repaymentMethod" label="是否支持线上放/还款">
                <ics-item-inner :prop="appForm.repaymentMethod" :format="(val)=>utils.statusFormat(Number(val), 'isOnLine')">
                  <el-radio-group v-model="appForm.repaymentMethod">
                    <el-radio v-for="item in constants.isOnLine" :key="item.value" :label="item.value">
                      {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="Number(attribute.method) === 1" :span="12">
              <el-form-item prop="repayment" label="还款方式">
                <ics-item-inner :prop="appForm.repayment" :format="(val)=>utils.statusFormat(String(val), 'repaymentType')">
                  <el-radio-group v-model="appForm.repayment" @change="changeRepaymentType">
                    <el-radio v-for="item in constants.repaymentType" :key="item.value" :label="item.value">
                      {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="serviceChargeMethod" label="服务费收取方式">
                <ics-item-inner :prop="appForm.serviceChargeMethod" :format="(val)=>utils.statusFormat(Number(val), 'ownFunds')">
                  <el-radio-group v-model="appForm.serviceChargeMethod">
                    <el-radio v-for="item in constants.ownFunds" :key="item.value" :label="item.value">
                      {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <div v-if="appForm.repayment === 'borrowing'">
              <el-col v-if="Number(attribute.amountRange) === 1" :span="12">
                <el-form-item prop="borrowingInfo.maxAmount" label="借贷金额上限(元)">
                  <ics-item-inner :prop="appForm.borrowingInfo.maxAmount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                    <el-input v-model="appForm.borrowingInfo.maxAmount" type="number" placeholder="请输入借贷金额上限" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="Number(attribute.minCycle) === 1" :span="12">
                <el-form-item label="免收费期限(天)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.minCycleMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.minCycleMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.minCycleMin" placeholder="请输入最小免收费期限" @input="(v) => (appForm.borrowingInfo.minCycleMin = v.replace(/\D|^0/g,''))" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.minCycleMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.minCycleMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.minCycleMax" placeholder="请输入最大免收费期限" @input="(v) => (appForm.borrowingInfo.minCycleMax = v.replace(/\D|^0/g,''))" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.minCycleMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.minCycleMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="Number(attribute.minCycle) === 1" :span="12">
                <el-form-item label="借贷期限(天)" prop="borrowingInfo.maxPeriod">
                  <ics-item-inner :prop="appForm.borrowingInfo.maxPeriod" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.borrowingInfo.maxPeriod" placeholder="请输入借贷期限" @input="(v) => (appForm.borrowingInfo.maxPeriod = v.replace(/\D|^0/g,''))" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.dayRate === '1'" :span="12">
                <el-form-item label="日利率(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.interestRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.interestRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.interestRateMin" placeholder="最小日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.interestRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.interestRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.interestRateMax" placeholder="最大日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.interestRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.interestRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.penaltyInterest === '1'" :span="12">
                <el-form-item label="罚息(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.penalTYtRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.penalTYtRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.penalTYtRateMin" placeholder="请输入最小罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.penalTYtRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.penalTYtRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.penalTYtRateMax" placeholder="请输入最大罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.penalTYtRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.penalTYtRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.overdue === '1'" :span="12">
                <el-form-item label="逾期期限">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.overdueMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.overdueMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.overdueMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.overdueMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.overdueMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.overdueMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.overdueMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.overdueMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="是否支持宽限期">
                  <p v-if="attribute.extension === '1'">
                    是
                  </p>
                  <p v-if="attribute.extension === '0'">
                    否
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="宽限期最大时长">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.maxExtensionMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.maxExtensionMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.maxExtensionMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.maxExtensionMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.maxExtensionMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.maxExtensionMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.maxExtensionMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.maxExtensionMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="宽限期利率(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.extensionRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.extensionRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.extensionRateMin" placeholder="最小宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.extensionRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.extensionRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.extensionRateMax" placeholder="最大宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.extensionRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.extensionRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.financing === '1'" :span="12">
                <el-form-item label="融资比例(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.financingMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.financingMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.financingMin" placeholder="最小融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.financingMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.financingMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.financingMax" placeholder="最大融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.financingMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.financingMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.serviceCharge === '1'" :span="12">
                <el-form-item label="服务费比例(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.serviceChargeMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.serviceChargeMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.serviceChargeMin" placeholder="最小服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="borrowingInfo.serviceChargeMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.borrowingInfo.serviceChargeMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.borrowingInfo.serviceChargeMax" placeholder="最大服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.borrowingInfo.serviceChargeMin) + ' ~ ' + utils.isEffectiveCommon(appForm.borrowingInfo.serviceChargeMax)) }}
                  </p>
                </el-form-item>
              </el-col>
            </div>
            <div v-if="appForm.repayment === 'firstRest'">
              <el-col v-if="Number(attribute.amountRange) === 1" :span="12">
                <el-form-item prop="firstRestInfo.maxAmount" label="借贷金额上限(元)">
                  <ics-item-inner :prop="appForm.firstRestInfo.maxAmount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                    <el-input v-model="appForm.firstRestInfo.maxAmount" type="number" placeholder="请输入借贷金额上限" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="Number(attribute.minCycle) === 1" :span="12">
                <el-form-item label="免收费期限(天)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.minCycleMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.minCycleMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.minCycleMin" placeholder="请输入最小免收费期限" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.minCycleMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.minCycleMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.minCycleMax" placeholder="请输入最大免收费期限" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.minCycleMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.minCycleMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="Number(attribute.minCycle) === 1" :span="12">
                <el-form-item label="借贷期限(天)" prop="firstRestInfo.maxPeriod">
                  <ics-item-inner :prop="appForm.firstRestInfo.maxPeriod" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.firstRestInfo.maxPeriod" placeholder="请输入借贷期限" @input="(v) => (appForm.firstRestInfo.maxPeriod = v.replace(/\D|^0/g,''))" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.dayRate === '1'" :span="12">
                <el-form-item label="日利率(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.interestRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.interestRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.interestRateMin" placeholder="最小日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.interestRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.interestRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.interestRateMax" placeholder="最大日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.interestRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.interestRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.penaltyInterest === '1'" :span="12">
                <el-form-item label="罚息(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.penalTYtRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.penalTYtRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.penalTYtRateMin" placeholder="请输入最小罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.penalTYtRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.penalTYtRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.penalTYtRateMax" placeholder="请输入最大罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.penalTYtRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.penalTYtRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.overdue === '1'" :span="12">
                <el-form-item label="逾期期限">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.overdueMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.overdueMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.overdueMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.overdueMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.overdueMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.overdueMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.overdueMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.overdueMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="是否支持宽限期">
                  <p v-if="attribute.extension === '1'">
                    是
                  </p>
                  <p v-if="attribute.extension === '0'">
                    否
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="宽限期最大时长">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.maxExtensionMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.maxExtensionMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.maxExtensionMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.maxExtensionMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.maxExtensionMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.maxExtensionMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.maxExtensionMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.maxExtensionMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="宽限期利率(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.extensionRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.extensionRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.extensionRateMin" placeholder="最小宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.extensionRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.extensionRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.extensionRateMax" placeholder="最大宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.extensionRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.extensionRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.financing === '1'" :span="12">
                <el-form-item label="融资比例(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.financingMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.financingMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.financingMin" placeholder="最小融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.financingMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.financingMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.financingMax" placeholder="最大融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.financingMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.financingMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.serviceCharge === '1'" :span="12">
                <el-form-item label="服务费比例(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.serviceChargeMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.serviceChargeMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.serviceChargeMin" placeholder="最小服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="firstRestInfo.serviceChargeMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.firstRestInfo.serviceChargeMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.firstRestInfo.serviceChargeMax" placeholder="最大服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.firstRestInfo.serviceChargeMin) + ' ~ ' + utils.isEffectiveCommon(appForm.firstRestInfo.serviceChargeMax)) }}
                  </p>
                </el-form-item>
              </el-col>
            </div>
            <div v-if="appForm.repayment === 'regular'">
              <el-col v-if="Number(attribute.amountRange) === 1" :span="12">
                <el-form-item prop="regularInfo.maxAmount" label="借贷金额上限(元)">
                  <ics-item-inner :prop="appForm.regularInfo.maxAmount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                    <el-input v-model="appForm.regularInfo.maxAmount" type="number" placeholder="请输入借贷金额上限" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="Number(attribute.minCycle) === 1" :span="12">
                <el-form-item label="免收费期限(天)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.minCycleMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.minCycleMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.minCycleMin" placeholder="请输入最小免收费期限" @input="(v) => (appForm.regularInfo.minCycleMin = v.replace(/\D|^0/g,''))" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.minCycleMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.minCycleMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.minCycleMax" placeholder="请输入最大免收费期限" @input="(v) => (appForm.regularInfo.minCycleMax = v.replace(/\D|^0/g,''))" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.minCycleMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.minCycleMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.dayRate === '1'" :span="12">
                <el-form-item label="日利率(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.interestRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.interestRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.interestRateMin" placeholder="最小日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.interestRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.interestRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.interestRateMax" placeholder="最大日利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.interestRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.interestRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.penaltyInterest === '1'" :span="12">
                <el-form-item label="罚息(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.penalTYtRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.penalTYtRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.penalTYtRateMin" placeholder="请输入最小罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.penalTYtRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.penalTYtRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.penalTYtRateMax" placeholder="请输入最大罚息" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.penalTYtRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.penalTYtRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.overdue === '1'" :span="12">
                <el-form-item label="逾期期限">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.overdueMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.overdueMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.overdueMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.overdueMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.overdueMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.overdueMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.overdueMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.overdueMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="是否支持宽限期">
                  <p v-if="attribute.extension === '1'">
                    是
                  </p>
                  <p v-if="attribute.extension === '0'">
                    否
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="宽限期最大时长">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.maxExtensionMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.maxExtensionMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.maxExtensionMin" placeholder="请输入最小时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.maxExtensionMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.maxExtensionMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.maxExtensionMax" placeholder="请输入最大时长" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.maxExtensionMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.maxExtensionMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="期数" prop="regularInfo.periods">
                  <ics-item-inner :prop="appForm.regularInfo.periods" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.regularInfo.periods" placeholder="请输入期数" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.extension === '1'" :span="12">
                <el-form-item label="宽限期利率(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.extensionRateMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.extensionRateMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.extensionRateMin" placeholder="最小宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.extensionRateMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.extensionRateMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.extensionRateMax" placeholder="最大宽限期利率" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.extensionRateMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.extensionRateMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.financing === '1'" :span="12">
                <el-form-item label="融资比例(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.financingMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.financingMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.financingMin" placeholder="最小融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.financingMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.financingMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.financingMax" placeholder="最大融资比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.financingMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.financingMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col v-if="attribute.serviceCharge === '1'" :span="12">
                <el-form-item label="服务费比例(%)">
                  <el-row v-if="viewEdit()">
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.serviceChargeMin" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.serviceChargeMin" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.serviceChargeMin" placeholder="最小服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="text-align:center;line-height:32px;">
                      ~
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="regularInfo.serviceChargeMax" style="margin-bottom: 0;">
                        <ics-item-inner :prop="appForm.regularInfo.serviceChargeMax" :format="utils.isEffectiveCommon">
                          <el-input v-model="appForm.regularInfo.serviceChargeMax" placeholder="最大服务费比例" />
                        </ics-item-inner>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <p v-if="!viewEdit()">
                    {{ (utils.isEffectiveCommon(appForm.regularInfo.serviceChargeMin) + ' ~ ' + utils.isEffectiveCommon(appForm.regularInfo.serviceChargeMax)) }}
                  </p>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="还款日" prop="regularInfo.repaymentDay">
                  <ics-item-inner :prop="appForm.regularInfo.repaymentDay" :format="utils.isEffectiveCommon">
                    <el-input v-model="appForm.regularInfo.repaymentDay" placeholder="请输入还款日" />
                  </ics-item-inner>
                </el-form-item>
              </el-col>
            </div>
          </ics-page-inner>
          <ics-page-inner title="业务覆盖城市">
            <ics-city-inner ref="city" :cap-id="appForm.capId" :city-info="cityList" :view-city="!viewEdit()" />
          </ics-page-inner>
          <ics-page-inner title="绑定合同模板">
            <div class="partition-table">
              <el-table ref="multipleTable" v-loading="loadingTem.list" :data="templateList" border style="width: 100%" class="table-input" max-height="360" @selection-change="handleSelectionChange">
                <el-table-column v-if="viewEdit()" type="selection" align="left" min-width="50" />
                <el-table-column v-if="viewEdit()" prop="contractTemplateNumber" label="模板编号" min-width="140" :formatter="utils.isEffective" />
                <el-table-column v-if="!viewEdit()" prop="templateCode" label="模板编号" min-width="140" :formatter="utils.isEffective" />
                <el-table-column prop="contractName" label="模板名称" min-width="140" :formatter="utils.isEffective" />
                <el-table-column label="模板文件" min-width="230">
                  <template v-slot="scope">
                    <span v-if="scope.row.contractUrl">
                      <a href="javascript:" class="text-btn" @click="utils.downloadP('concat', scope.row.contractUrl)">
                        {{ utils.isEffectiveCommon(scope.row.contractUrlName) }}
                      </a>
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column prop="capName" label="资金方" min-width="160" :formatter="utils.isEffective" />
                <el-table-column prop="createdBy" label="创建人" min-width="150" :formatter="utils.isEffective" />
                <el-table-column label="签署方式" label-class-name="required-th" align="center" min-width="170">
                  <template v-slot="scope">
                    <el-form-item v-if="viewEdit()" label-width="120">
                      <el-radio-group v-model="scope.row.signingMethod">
                        <el-radio v-for="item in constants.signingMethodType" :key="item.value" :label="item.value">
                          {{ item.label }}
                        </el-radio>
                      </el-radio-group>
                      <template slot="error" slot-scope="errScope">
                        <table-form-error-tooltip :err-scope="errScope" />
                      </template>
                    </el-form-item>
                    <span v-else>{{ utils.statusFormat(Number(scope.row.signingMethod), 'signingMethodType') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </ics-page-inner>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCityInner from '../components/city-inner'
export default {
  components: { IcsCityInner },
  mixins: [routeEnterMixin],
  data () {
    const validateMaxPeriod = (rule, value, callback) => {
      if (Number(value) < 1) {
        callback(new Error('免收费期限不能小于1'))
      } else {
        callback()
      }
    }
    const validateCycleMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('免收费期限不能小于0'))
      } else {
        callback()
      }
    }
    const validateCycleMax = (rule, value, callback) => {
      if (this.appForm.minCycleMin) {
        if (Number(this.appForm.minCycleMin) <= Number(value)) {
          callback()
        } else {
          callback(new Error('最大周期不能小于最小周期'))
        }
      } else {
        callback()
      }
    }
    const validateInterestMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('罚息不能小于0'))
      } else {
        callback()
      }
    }
    const validateInterestMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('罚息不能大于100'))
      } else {
        if (this.appForm.penalTYtRateMin) {
          if (Number(this.appForm.penalTYtRateMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大罚息低于最小罚息'))
          }
        } else {
          callback()
        }
      }
    }
    const validatePenaltytMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('日利率不能小于0'))
      } else {
        callback()
      }
    }
    const validatePenaltytMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('日利率不能大于100'))
      } else {
        if (this.appForm.interestRateMin) {
          if (Number(this.appForm.interestRateMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大日利率低于最小日利率'))
          }
        } else {
          callback()
        }
      }
    }
    const validateOverdueMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小逾期期限不能小于0'))
      } else {
        callback()
      }
    }
    const validateOverdueMax = (rule, value, callback) => {
      if (this.appForm.overdueMin) {
        if (Number(this.appForm.overdueMin) <= Number(value)) {
          callback()
        } else {
          callback(new Error('最大时长不能小于最小时长'))
        }
      } else {
        callback()
      }
    }
    const validateMaxExtensionMin = (rule, value, callback) => {
      if (Number(value) < 1) {
        callback(new Error('最小宽限期时长不能小于1'))
      } else {
        callback()
      }
    }
    const validateMaxExtensionMax = (rule, value, callback) => {
      if (this.appForm.maxExtensionMin) {
        if (Number(this.appForm.maxExtensionMin) <= Number(value)) {
          callback()
        } else {
          callback(new Error('最大时长低于最小时长'))
        }
      } else {
        callback()
      }
    }
    const validateExtensionRateMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小宽限期利率不能小于0'))
      } else {
        callback()
      }
    }
    const validateExtensionRateMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('最大利率不能大于100'))
      } else {
        if (this.appForm.extensionRateMin) {
          if (Number(this.appForm.extensionRateMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大利率低于最小利率'))
          }
        } else {
          callback()
        }
      }
    }
    const validateFinancingMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小融资比例不能小于0'))
      } else {
        callback()
      }
    }
    const validateFinancingMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('融资比例不能大于100'))
      } else {
        if (this.appForm.financingMin) {
          if (Number(this.appForm.financingMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大比例低于最小比例'))
          }
        } else {
          callback()
        }
      }
    }
    const validateServiceChargeMin = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('最小比例不能小于0'))
      } else {
        callback()
      }
    }
    const validateServiceChargeMax = (rule, value, callback) => {
      if (Number(value) > 100) {
        callback(new Error('比例不能大于100'))
      } else {
        if (this.appForm.serviceChargeMin) {
          if (Number(this.appForm.serviceChargeMin) <= Number(value)) {
            callback()
          } else {
            callback(new Error('最大比例低于最小比例'))
          }
        } else {
          callback()
        }
      }
    }
    const validateRepaymentDay = (rule, value, callback) => {
      if (Number(value) > 28) {
        callback(new Error('还款日不能大于28'))
      } else if (Number(value) < 0) {
        callback(new Error('还款日不能小于0'))
      } else {
        callback()
      }
    }
    return {
      productCode: this.$route.query.productCode,
      validateFlag: 0,
      appForm: {
        productName: '',
        capId: '',
        capCode: '',
        capName: '',
        modelId: '',
        productFinancing: '',
        productType: '',
        productTypeName: '',
        repayment: 'borrowing',
        repaymentMethod: 0,
        serviceChargeMethod: 0,
        borrowingInfo: {
          maxAmount: '',
          maxPeriod: '',
          penalTYtRateMin: '',
          penalTYtRateMax: '',
          minCycleMin: '',
          minCycleMax: '',
          interestRateMin: '',
          interestRateMax: '',
          overdueMin: '',
          overdueMax: '',
          maxExtensionMin: '',
          maxExtensionMax: '',
          extensionRateMin: '',
          extensionRateMax: '',
          financingMin: '',
          financingMax: '',
          serviceChargeMin: '',
          serviceChargeMax: '',
        },
        firstRestInfo: {
          maxAmount: '',
          maxPeriod: '',
          penalTYtRateMin: '',
          penalTYtRateMax: '',
          minCycleMin: '',
          minCycleMax: '',
          interestRateMin: '',
          interestRateMax: '',
          overdueMin: '',
          overdueMax: '',
          maxExtensionMin: '',
          maxExtensionMax: '',
          extensionRateMin: '',
          extensionRateMax: '',
          financingMin: '',
          financingMax: '',
          serviceChargeMin: '',
          serviceChargeMax: '',
        },
        regularInfo: {
          maxAmount: '',
          maxPeriod: '',
          penalTYtRateMin: '',
          penalTYtRateMax: '',
          minCycleMin: '',
          minCycleMax: '',
          interestRateMin: '',
          interestRateMax: '',
          overdueMin: '',
          overdueMax: '',
          maxExtensionMin: '',
          maxExtensionMax: '',
          extensionRateMin: '',
          extensionRateMax: '',
          financingMin: '',
          financingMax: '',
          serviceChargeMin: '',
          serviceChargeMax: '',
          repaymentDay: '',
          periods: ''
        },
      },
      loadingTem: {
        list: false
      },
      multipleSelection: undefined,
      attribute: {},
      cityList: [],
      templateList: [],
      setTemplateList: [],
      capList: [],
      modelList: [],
      rules: {
        capId: [
          { required: true, message: '请选择资方', trigger: 'blur' }
        ],
        productName: [
          { required: true, message: '请输入产品名称', trigger: 'blur' }
        ],
        modelId: [
          { required: true, message: '请选择产品模型', trigger: 'blur' }
        ],
        productFinancing: [
          { required: true, message: '请选择产品类型', trigger: 'blur' }
        ],
        repayment: [
          { required: true, message: '请选择还款方式', trigger: 'blur' }
        ],
        borrowingInfo: {
          maxAmount: [
            { required: false, message: '请输入借贷金额上限', trigger: 'blur' }
          ],
          maxPeriod: [
            { required: false, message: '请输入借贷期限上限', trigger: 'blur' },
            { validator: validateMaxPeriod, trigger: 'blur' }
          ],
          interestRateMin: [
            { required: false, message: '请输入最小日利率', trigger: 'blur' },
            { validator: validatePenaltytMin, trigger: 'blur' }
          ],
          interestRateMax: [
            { required: false, message: '请输入最大日利率', trigger: 'blur' },
            { validator: validatePenaltytMax, trigger: 'blur' }
          ],
          penalTYtRateMin: [
            { required: false, message: '请输入最小罚息', trigger: 'blur' },
            { validator: validateInterestMin, trigger: 'blur' }
          ],
          penalTYtRateMax: [
            { required: false, message: '请输入最大罚息', trigger: 'blur' },
            { validator: validateInterestMax, trigger: 'blur' }
          ],
          minCycleMin: [
            { required: false, message: '请输入最小免收费期限', trigger: 'blur' },
            { validator: validateCycleMin, trigger: 'blur' }
          ],
          minCycleMax: [
            { required: false, message: '请输入最大免收费期限', trigger: 'blur' },
            { validator: validateCycleMax, trigger: 'blur' }
          ],
          overdueMin: [
            { required: false, message: '请输入最小时长', trigger: 'blur' },
            { validator: validateOverdueMin, trigger: 'blur' }
          ],
          overdueMax: [
            { required: false, message: '请输入最大时长', trigger: 'blur' },
            { validator: validateOverdueMax, trigger: 'blur' }
          ],
          maxExtensionMin: [
            { required: false, message: '请输入最小时长', trigger: 'blur' },
            { validator: validateMaxExtensionMin, trigger: 'blur' }
          ],
          maxExtensionMax: [
            { required: false, message: '请输入最大时长', trigger: 'blur' },
            { validator: validateMaxExtensionMax, trigger: 'blur' }
          ],
          extensionRateMin: [
            { required: false, message: '请输入最小宽限期利率', trigger: 'blur' },
            { validator: validateExtensionRateMin, trigger: 'blur' }
          ],
          extensionRateMax: [
            { required: false, message: '请输入最大宽限期利率', trigger: 'blur' },
            { validator: validateExtensionRateMax, trigger: 'blur' }
          ],
          financingMin: [
            { required: false, message: '请输入最小融资比例', trigger: 'blur' },
            { validator: validateFinancingMin, trigger: 'blur' }
          ],
          financingMax: [
            { required: false, message: '请输入最大融资比例', trigger: 'blur' },
            { validator: validateFinancingMax, trigger: 'blur' }
          ],
          serviceChargeMin: [
            { required: false, message: '请输入最小比例', trigger: 'blur' },
            { validator: validateServiceChargeMin, trigger: 'blur' }
          ],
          serviceChargeMax: [
            { required: false, message: '请输入最大比例', trigger: 'blur' },
            { validator: validateServiceChargeMax, trigger: 'blur' }
          ],
        },
        firstRestInfo: {
          maxAmount: [
            { required: false, message: '请输入借贷金额上限', trigger: 'blur' }
          ],
          maxPeriod: [
            { required: false, message: '请输入借贷期限上限', trigger: 'blur' },
            { validator: validateMaxPeriod, trigger: 'blur' }
          ],
          interestRateMin: [
            { required: false, message: '请输入最小日利率', trigger: 'blur' },
            { validator: validatePenaltytMin, trigger: 'blur' }
          ],
          interestRateMax: [
            { required: false, message: '请输入最大日利率', trigger: 'blur' },
            { validator: validatePenaltytMax, trigger: 'blur' }
          ],
          penalTYtRateMin: [
            { required: false, message: '请输入最小罚息', trigger: 'blur' },
            { validator: validateInterestMin, trigger: 'blur' }
          ],
          penalTYtRateMax: [
            { required: false, message: '请输入最大罚息', trigger: 'blur' },
            { validator: validateInterestMax, trigger: 'blur' }
          ],
          minCycleMin: [
            { required: false, message: '请输入最小免收费期限', trigger: 'blur' },
            { validator: validateCycleMin, trigger: 'blur' }
          ],
          minCycleMax: [
            { required: false, message: '请输入最大免收费期限', trigger: 'blur' },
            { validator: validateCycleMax, trigger: 'blur' }
          ],
          overdueMin: [
            { required: false, message: '请输入最小时长', trigger: 'blur' },
            { validator: validateOverdueMin, trigger: 'blur' }
          ],
          overdueMax: [
            { required: false, message: '请输入最大时长', trigger: 'blur' },
            { validator: validateOverdueMax, trigger: 'blur' }
          ],
          maxExtensionMin: [
            { required: false, message: '请输入最小时长', trigger: 'blur' },
            { validator: validateMaxExtensionMin, trigger: 'blur' }
          ],
          maxExtensionMax: [
            { required: false, message: '请输入最大时长', trigger: 'blur' },
            { validator: validateMaxExtensionMax, trigger: 'blur' }
          ],
          extensionRateMin: [
            { required: false, message: '请输入最小宽限期利率', trigger: 'blur' },
            { validator: validateExtensionRateMin, trigger: 'blur' }
          ],
          extensionRateMax: [
            { required: false, message: '请输入最大宽限期利率', trigger: 'blur' },
            { validator: validateExtensionRateMax, trigger: 'blur' }
          ],
          financingMin: [
            { required: false, message: '请输入最小融资比例', trigger: 'blur' },
            { validator: validateFinancingMin, trigger: 'blur' }
          ],
          financingMax: [
            { required: false, message: '请输入最大融资比例', trigger: 'blur' },
            { validator: validateFinancingMax, trigger: 'blur' }
          ],
          serviceChargeMin: [
            { required: false, message: '请输入最小比例', trigger: 'blur' },
            { validator: validateServiceChargeMin, trigger: 'blur' }
          ],
          serviceChargeMax: [
            { required: false, message: '请输入最大比例', trigger: 'blur' },
            { validator: validateServiceChargeMax, trigger: 'blur' }
          ],
        },
        regularInfo: {
          maxAmount: [
            { required: false, message: '请输入借贷金额上限', trigger: 'blur' }
          ],
          interestRateMin: [
            { required: false, message: '请输入最小日利率', trigger: 'blur' },
            { validator: validatePenaltytMin, trigger: 'blur' }
          ],
          interestRateMax: [
            { required: false, message: '请输入最大日利率', trigger: 'blur' },
            { validator: validatePenaltytMax, trigger: 'blur' }
          ],
          penalTYtRateMin: [
            { required: false, message: '请输入最小罚息', trigger: 'blur' },
            { validator: validateInterestMin, trigger: 'blur' }
          ],
          penalTYtRateMax: [
            { required: false, message: '请输入最大罚息', trigger: 'blur' },
            { validator: validateInterestMax, trigger: 'blur' }
          ],
          minCycleMin: [
            { required: false, message: '请输入最小免收费期限', trigger: 'blur' },
            { validator: validateCycleMin, trigger: 'blur' }
          ],
          minCycleMax: [
            { required: false, message: '请输入最大免收费期限', trigger: 'blur' },
            { validator: validateCycleMax, trigger: 'blur' }
          ],
          overdueMin: [
            { required: false, message: '请输入最小时长', trigger: 'blur' },
            { validator: validateOverdueMin, trigger: 'blur' }
          ],
          overdueMax: [
            { required: false, message: '请输入最大时长', trigger: 'blur' },
            { validator: validateOverdueMax, trigger: 'blur' }
          ],
          maxExtensionMin: [
            { required: false, message: '请输入最小时长', trigger: 'blur' },
            { validator: validateMaxExtensionMin, trigger: 'blur' }
          ],
          maxExtensionMax: [
            { required: false, message: '请输入最大时长', trigger: 'blur' },
            { validator: validateMaxExtensionMax, trigger: 'blur' }
          ],
          extensionRateMin: [
            { required: false, message: '请输入最小宽限期利率', trigger: 'blur' },
            { validator: validateExtensionRateMin, trigger: 'blur' }
          ],
          extensionRateMax: [
            { required: false, message: '请输入最大宽限期利率', trigger: 'blur' },
            { validator: validateExtensionRateMax, trigger: 'blur' }
          ],
          financingMin: [
            { required: false, message: '请输入最小融资比例', trigger: 'blur' },
            { validator: validateFinancingMin, trigger: 'blur' }
          ],
          financingMax: [
            { required: false, message: '请输入最大融资比例', trigger: 'blur' },
            { validator: validateFinancingMax, trigger: 'blur' }
          ],
          serviceChargeMin: [
            { required: false, message: '请输入最小比例', trigger: 'blur' },
            { validator: validateServiceChargeMin, trigger: 'blur' }
          ],
          serviceChargeMax: [
            { required: false, message: '请输入最大比例', trigger: 'blur' },
            { validator: validateServiceChargeMax, trigger: 'blur' }
          ],
          repaymentDay: [
            { required: false, message: '请输入还款日', trigger: 'blur' },
            { validator: validateRepaymentDay, trigger: 'blur' }
          ],
          periods: [
            { required: false, message: '请输入期数', trigger: 'blur' }
          ],
        }
      }
    }
  },
  created () {
    if (this.viewEdit()) {
      this.getCapCompanyList()
    }
    if (this.productCode) {
      this.getDetail()
    }
    this.changeRepaymentType()
    this.getListModelProduct()
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.product.product.getDetail({ productCode: this.productCode }).then(result => {
        const data = result.data.data
        let overdue = ''
        let maxExtension = ''
        let extensionRate = ''
        let penalTYtRate = ''
        let interestRate = ''
        let minCycle = ''
        let financing = ''
        let serviceCharge = ''
        if (data.info.minCycle) {
          minCycle = JSON.parse(data.info.minCycle)
          data.info.minCycleMin = minCycle.min
          data.info.minCycleMax = minCycle.max
        }
        if (data.info.interestRate) {
          interestRate = JSON.parse(data.info.interestRate)
          data.info.interestRateMin = interestRate.min
          data.info.interestRateMax = interestRate.max
        }
        if (data.info.penaltytRate) {
          penalTYtRate = JSON.parse(data.info.penaltytRate)
          data.info.penalTYtRateMin = penalTYtRate.min
          data.info.penalTYtRateMax = penalTYtRate.max
        }
        if (data.info.overdue) {
          overdue = JSON.parse(data.info.overdue)
          data.info.overdueMin = overdue.min
          data.info.overdueMax = overdue.max
        }
        if (data.info.maxExtension) {
          maxExtension = JSON.parse(data.info.maxExtension)
          data.info.maxExtensionMin = maxExtension.min
          data.info.maxExtensionMax = maxExtension.max
        }
        if (data.info.extensionRate) {
          extensionRate = JSON.parse(data.info.extensionRate)
          data.info.extensionRateMin = extensionRate.min
          data.info.extensionRateMax = extensionRate.max
        }
        if (data.info.financing) {
          financing = JSON.parse(data.info.financing)
          data.info.financingMin = financing.min
          data.info.financingMax = financing.max
        }
        if (data.info.serviceCharge) {
          serviceCharge = JSON.parse(data.info.serviceCharge)
          data.info.serviceChargeMin = serviceCharge.min
          data.info.serviceChargeMax = serviceCharge.max
        }
        this.modelChange(data.info.modelId)
        if (this.viewEdit()) {
          this.setTemplateList = data.templateDtos || []
          this.getTemplateList(data.info.capCode)
        } else {
          this.templateList = data.templateDtos
        }
        this.appForm.productName = data.info.productName
        this.appForm.productCode = data.info.productCode
        this.appForm.capId = data.info.capId
        this.appForm.capCode = data.info.capCode
        this.appForm.capName = data.info.capName
        this.appForm.modelId = data.info.modelId
        this.appForm.productFinancing = data.info.productFinancing
        this.appForm.productType = data.info.productType
        this.appForm.productTypeName = data.info.productTypeName
        this.appForm.repayment = data.info.repayment
        this.appForm.repaymentMethod = data.info.repaymentMethod
        this.dataProcessing(data.info)
        console.log(this.appForm)
        data.cityList.forEach(item => {
          this.cityList.push(item.areaCode)
        })
      }).finally(() => {
        this.loading.detail = false
      })
    },
    dataProcessing(row) {
      const data = row || {}
      if (data.repayment === 'borrowing') {
        this.appForm.borrowingInfo.maxAmount = data.maxAmount
        this.appForm.borrowingInfo.maxPeriod = data.maxPeriod
        this.appForm.borrowingInfo.penalTYtRateMin = data.penalTYtRateMin
        this.appForm.borrowingInfo.penalTYtRateMax = data.penalTYtRateMax
        this.appForm.borrowingInfo.minCycleMin = data.minCycleMin
        this.appForm.borrowingInfo.minCycleMax = data.minCycleMax
        this.appForm.borrowingInfo.interestRateMin = data.interestRateMin
        this.appForm.borrowingInfo.interestRateMax = data.interestRateMax
        this.appForm.borrowingInfo.overdueMin = data.overdueMin
        this.appForm.borrowingInfo.overdueMax = data.overdueMax
        this.appForm.borrowingInfo.maxExtensionMin = data.maxExtensionMin
        this.appForm.borrowingInfo.maxExtensionMax = data.maxExtensionMax
        this.appForm.borrowingInfo.extensionRateMin = data.extensionRateMin
        this.appForm.borrowingInfo.extensionRateMax = data.extensionRateMax
        this.appForm.borrowingInfo.financingMin = data.financingMin
        this.appForm.borrowingInfo.financingMax = data.financingMax
        this.appForm.borrowingInfo.serviceChargeMin = data.serviceChargeMin
        this.appForm.borrowingInfo.serviceChargeMax = data.serviceChargeMax
      } else if (data.repayment === 'firstRest') {
        this.appForm.firstRestInfo.extensionRateMax = data.extensionRateMax
        this.appForm.firstRestInfo.maxAmount = data.maxAmount
        this.appForm.firstRestInfo.maxPeriod = data.maxPeriod
        this.appForm.firstRestInfo.penalTYtRateMin = data.penalTYtRateMin
        this.appForm.firstRestInfo.penalTYtRateMax = data.penalTYtRateMax
        this.appForm.firstRestInfo.minCycleMin = data.minCycleMin
        this.appForm.firstRestInfo.minCycleMax = data.minCycleMax
        this.appForm.firstRestInfo.interestRateMin = data.interestRateMin
        this.appForm.firstRestInfo.interestRateMax = data.interestRateMax
        this.appForm.firstRestInfo.overdueMin = data.overdueMin
        this.appForm.firstRestInfo.overdueMax = data.overdueMax
        this.appForm.firstRestInfo.maxExtensionMin = data.maxExtensionMin
        this.appForm.firstRestInfo.maxExtensionMax = data.maxExtensionMax
        this.appForm.firstRestInfo.extensionRateMin = data.extensionRateMin
        this.appForm.firstRestInfo.extensionRateMax = data.extensionRateMax
        this.appForm.firstRestInfo.financingMin = data.financingMin
        this.appForm.firstRestInfo.financingMax = data.financingMax
        this.appForm.firstRestInfo.serviceChargeMin = data.serviceChargeMin
        this.appForm.firstRestInfo.serviceChargeMax = data.serviceChargeMax
      } else if (data.repayment === 'regular') {
        this.appForm.regularInfo.maxAmount = data.maxAmount
        this.appForm.regularInfo.penalTYtRateMin = data.penalTYtRateMin
        this.appForm.regularInfo.penalTYtRateMax = data.penalTYtRateMax
        this.appForm.regularInfo.minCycleMin = data.minCycleMin
        this.appForm.regularInfo.minCycleMax = data.minCycleMax
        this.appForm.regularInfo.interestRateMin = data.interestRateMin
        this.appForm.regularInfo.interestRateMax = data.interestRateMax
        this.appForm.regularInfo.overdueMin = data.overdueMin
        this.appForm.regularInfo.overdueMax = data.overdueMax
        this.appForm.regularInfo.maxExtensionMin = data.maxExtensionMin
        this.appForm.regularInfo.maxExtensionMax = data.maxExtensionMax
        this.appForm.regularInfo.extensionRateMin = data.extensionRateMin
        this.appForm.regularInfo.extensionRateMax = data.extensionRateMax
        this.appForm.regularInfo.financingMin = data.financingMin
        this.appForm.regularInfo.financingMax = data.financingMax
        this.appForm.regularInfo.serviceChargeMin = data.serviceChargeMin
        this.appForm.regularInfo.serviceChargeMax = data.serviceChargeMax
        this.appForm.regularInfo.periods = data.periods
        this.appForm.regularInfo.repaymentDay = data.repaymentDay
      } else {
        return false
      }
    },
    getListModelProduct () {
      this.api.product.model.listModelProduct().then(result => {
        this.modelList = result.data.data || []
      }).finally(() => {
      })
    },
    getCapCompanyList () {
      this.api.company.capital.getCapList().then(result => {
        this.capList = result.data.data || []
      }).finally(() => {
      })
    },
    changeRepaymentType (val) {
      const data = this.appForm.repayment ? this.appForm.repayment : val
      if (this.validateFlag === 1) {
        this.$refs.appForm.clearValidate()
      }
      this.changeRepaymentInitial()
      switch (data) {
        case 'borrowing':
          this.validateFlag = 1
          this.utils.requiredChange(true, this.rules.borrowingInfo)
          break
        case 'firstRest':
          this.validateFlag = 1
          this.utils.requiredChange(true, this.rules.firstRestInfo)
          break
        case 'regular':
          this.validateFlag = 1
          this.utils.requiredChange(true, this.rules.regularInfo)
          break
        default:
          this.utils.requiredChange(false, this.rules.borrowingInfo)
          this.utils.requiredChange(false, this.rules.firstRestInfo)
          this.utils.requiredChange(false, this.rules.regularInfo)
          break
      }
    },
    changeRepaymentInitial () {
      this.appForm.borrowingInfo.maxAmount = ''
      this.appForm.borrowingInfo.maxPeriod = ''
      this.appForm.borrowingInfo.penalTYtRateMin = ''
      this.appForm.borrowingInfo.penalTYtRateMax = ''
      this.appForm.borrowingInfo.minCycleMin = ''
      this.appForm.borrowingInfo.minCycleMax = ''
      this.appForm.borrowingInfo.interestRateMin = ''
      this.appForm.borrowingInfo.interestRateMax = ''
      this.appForm.borrowingInfo.overdueMin = ''
      this.appForm.borrowingInfo.overdueMax = ''
      this.appForm.borrowingInfo.maxExtensionMin = ''
      this.appForm.borrowingInfo.maxExtensionMax = ''
      this.appForm.borrowingInfo.extensionRateMin = ''
      this.appForm.borrowingInfo.extensionRateMax = ''
      this.appForm.borrowingInfo.financingMin = ''
      this.appForm.borrowingInfo.financingMax = ''
      this.appForm.borrowingInfo.serviceChargeMin = ''
      this.appForm.borrowingInfo.serviceChargeMax = ''
      this.appForm.firstRestInfo.maxAmount = ''
      this.appForm.firstRestInfo.maxPeriod = ''
      this.appForm.firstRestInfo.penalTYtRateMin = ''
      this.appForm.firstRestInfo.penalTYtRateMax = ''
      this.appForm.firstRestInfo.minCycleMin = ''
      this.appForm.firstRestInfo.minCycleMax = ''
      this.appForm.firstRestInfo.interestRateMin = ''
      this.appForm.firstRestInfo.interestRateMax = ''
      this.appForm.firstRestInfo.overdueMin = ''
      this.appForm.firstRestInfo.overdueMax = ''
      this.appForm.firstRestInfo.maxExtensionMin = ''
      this.appForm.firstRestInfo.maxExtensionMax = ''
      this.appForm.firstRestInfo.extensionRateMin = ''
      this.appForm.firstRestInfo.extensionRateMax = ''
      this.appForm.firstRestInfo.financingMin = ''
      this.appForm.firstRestInfo.financingMax = ''
      this.appForm.firstRestInfo.serviceChargeMin = ''
      this.appForm.firstRestInfo.serviceChargeMax = ''
      this.appForm.regularInfo.maxAmount = ''
      this.appForm.regularInfo.penalTYtRateMin = ''
      this.appForm.regularInfo.penalTYtRateMax = ''
      this.appForm.regularInfo.minCycleMin = ''
      this.appForm.regularInfo.minCycleMax = ''
      this.appForm.regularInfo.interestRateMin = ''
      this.appForm.regularInfo.interestRateMax = ''
      this.appForm.regularInfo.overdueMin = ''
      this.appForm.regularInfo.overdueMax = ''
      this.appForm.regularInfo.maxExtensionMin = ''
      this.appForm.regularInfo.maxExtensionMax = ''
      this.appForm.regularInfo.extensionRateMin = ''
      this.appForm.regularInfo.extensionRateMax = ''
      this.appForm.regularInfo.financingMin = ''
      this.appForm.regularInfo.financingMax = ''
      this.appForm.regularInfo.serviceChargeMin = ''
      this.appForm.regularInfo.serviceChargeMax = ''
      this.appForm.regularInfo.repaymentDay = ''
      this.appForm.regularInfo.periods = ''
    },
    companyChange (val) {
      const data = this._.find(this.capList, { capCode: val }) || {}
      const { capId, capName } = data
      this.appForm = this._.assign(this.appForm, { capId, capName })
      this.getTemplateList(val)
    },
    getTemplateList (val) {
      this.loadingTem.list = true
      const data = {capCod: val}
      this.api.contract.template.getTemplateList(data).then(result => {
        const data = result.data.data || []
        data.forEach(item => {
          if (!this._.isEmpty(this.setTemplateList)) {
            this.setTemplateList.forEach(val => {
              if (val.templateCode === item.contractTemplateNumber) {
                item.signingMethod = val.signingMethod
              }
            })
          } else {
            item.signingMethod = ''
          }
        })
        this.templateList = data
        this.toggleSelection()
      }).finally(() => {
        this.loadingTem.list = false
      })
    },
    toggleSelection() {
      this.$nextTick(() => {
        this.setTemplateList.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(
            this.templateList.find(item => { return row.templateCode === item.contractTemplateNumber}, true))
        })
      })
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    modelChange (val) {
      const data = this._.find(this.modelList, { id: val }) || {}
      data.productType = data.modelCode
      data.productTypeName = data.modelName
      if (data.attribute) {
        this.attribute = JSON.parse(data.attribute)
      }
      const { productType, productTypeName } = data
      this.appForm = this._.assign(this.appForm, { productType, productTypeName })
    },
    dataEncapsulation () {
      const data = this._.cloneDeep(this.appForm)
      if (data.repayment === 'borrowing') {
        data.maxAmount = data.borrowingInfo.maxAmount
        data.maxPeriod = data.borrowingInfo.maxPeriod
        data.repaymentDay = ''
        data.periods = ''
        console.log(data)
        if (data.borrowingInfo.minCycleMin && data.borrowingInfo.minCycleMax) {
          data.minCycleJson = JSON.stringify({min: data.borrowingInfo.minCycleMin, max: data.borrowingInfo.minCycleMax})
        }
        if (data.borrowingInfo.interestRateMin && data.borrowingInfo.interestRateMax) {
          data.interestRateJson = JSON.stringify({min: data.borrowingInfo.interestRateMin, max: data.borrowingInfo.interestRateMax})
        }
        if (data.borrowingInfo.penalTYtRateMin && data.borrowingInfo.penalTYtRateMax) {
          data.penalTYtRateJson = JSON.stringify({min: data.borrowingInfo.penalTYtRateMin, max: data.borrowingInfo.penalTYtRateMax})
        }
        if (data.borrowingInfo.overdueMin && data.borrowingInfo.overdueMax) {
          data.overdueJson = JSON.stringify({min: data.borrowingInfo.overdueMin, max: data.borrowingInfo.overdueMax})
        }
        if (data.borrowingInfo.maxExtensionMin && data.borrowingInfo.maxExtensionMax) {
          data.maxExtensionJson = JSON.stringify({min: data.borrowingInfo.maxExtensionMin, max: data.borrowingInfo.maxExtensionMax})
        }
        console.log(data.borrowingInfo.extensionRateMin && data.borrowingInfo.extensionRateMax)
        if (data.borrowingInfo.extensionRateMin && data.borrowingInfo.extensionRateMax) {
          data.extensionRateJson = JSON.stringify({min: data.borrowingInfo.extensionRateMin, max: data.borrowingInfo.extensionRateMax})
        }
        if (data.borrowingInfo.financingMin && data.borrowingInfo.financingMax) {
          data.financingJson = JSON.stringify({min: data.borrowingInfo.financingMin, max: data.borrowingInfo.financingMax})
        }
        if (data.borrowingInfo.serviceChargeMin && data.borrowingInfo.serviceChargeMax) {
          data.serviceChargeJson = JSON.stringify({min: data.borrowingInfo.serviceChargeMin, max: data.borrowingInfo.serviceChargeMax})
        }
        if (Number(this.attribute.extension) === 1) { data.roll = 'yes' } else { data.roll = 'no' }
        return data
      } else if (data.repayment === 'firstRest') {
        data.maxAmount = data.firstRestInfo.maxAmount
        data.maxPeriod = data.firstRestInfo.maxPeriod
        data.repaymentDay = ''
        data.periods = ''
        if (data.firstRestInfo.minCycleMin && data.firstRestInfo.minCycleMax) {
          data.minCycleJson = JSON.stringify({min: data.firstRestInfo.minCycleMin, max: data.firstRestInfo.minCycleMax})
        }
        if (data.firstRestInfo.interestRateMin && data.firstRestInfo.interestRateMax) {
          data.interestRateJson = JSON.stringify({min: data.firstRestInfo.interestRateMin, max: data.firstRestInfo.interestRateMax})
        }
        if (data.firstRestInfo.penalTYtRateMin && data.firstRestInfo.penalTYtRateMax) {
          data.penalTYtRateJson = JSON.stringify({min: data.firstRestInfo.penalTYtRateMin, max: data.firstRestInfo.penalTYtRateMax})
        }
        if (data.firstRestInfo.overdueMin && data.firstRestInfo.overdueMax) {
          data.overdueJson = JSON.stringify({min: data.firstRestInfo.overdueMin, max: data.firstRestInfo.overdueMax})
        }
        if (data.firstRestInfo.maxExtensionMin && data.firstRestInfo.maxExtensionMax) {
          data.maxExtensionJson = JSON.stringify({min: data.firstRestInfo.maxExtensionMin, max: data.firstRestInfo.maxExtensionMax})
        }
        if (data.firstRestInfo.extensionRateMin && data.firstRestInfo.extensionRateMax) {
          data.extensionRateJson = JSON.stringify({min: data.firstRestInfo.extensionRateMin, max: data.firstRestInfo.extensionRateMax})
        }
        if (data.firstRestInfo.financingMin && data.firstRestInfo.financingMax) {
          data.financingJson = JSON.stringify({min: data.firstRestInfo.financingMin, max: data.firstRestInfo.financingMax})
        }
        if (data.firstRestInfo.serviceChargeMin && data.firstRestInfo.serviceChargeMax) {
          data.serviceChargeJson = JSON.stringify({min: data.firstRestInfo.serviceChargeMin, max: data.firstRestInfo.serviceChargeMax})
        }
        if (Number(this.attribute.extension) === 1) { data.roll = 'yes' } else { data.roll = 'no' }
        return data
      } else if (data.repayment === 'regular') {
        data.maxAmount = data.regularInfo.maxAmount
        data.maxPeriod = ''
        data.repaymentDay = data.regularInfo.repaymentDay
        data.periods = data.regularInfo.periods
        if (data.regularInfo.minCycleMin && data.regularInfo.minCycleMax) {
          data.minCycleJson = JSON.stringify({min: data.regularInfo.minCycleMin, max: data.regularInfo.minCycleMax})
        }
        if (data.regularInfo.interestRateMin && data.regularInfo.interestRateMax) {
          data.interestRateJson = JSON.stringify({min: data.regularInfo.interestRateMin, max: data.regularInfo.interestRateMax})
        }
        if (data.regularInfo.penalTYtRateMin && data.regularInfo.penalTYtRateMax) {
          data.penalTYtRateJson = JSON.stringify({min: data.regularInfo.penalTYtRateMin, max: data.regularInfo.penalTYtRateMax})
        }
        if (data.regularInfo.overdueMin && data.regularInfo.overdueMax) {
          data.overdueJson = JSON.stringify({min: data.regularInfo.overdueMin, max: data.regularInfo.overdueMax})
        }
        if (data.regularInfo.maxExtensionMin && data.regularInfo.maxExtensionMax) {
          data.maxExtensionJson = JSON.stringify({min: data.regularInfo.maxExtensionMin, max: data.regularInfo.maxExtensionMax})
        }
        if (data.regularInfo.extensionRateMin && data.regularInfo.extensionRateMax) {
          data.extensionRateJson = JSON.stringify({min: data.regularInfo.extensionRateMin, max: data.regularInfo.extensionRateMax})
        }
        if (data.regularInfo.financingMin && data.regularInfo.financingMax) {
          data.financingJson = JSON.stringify({min: data.regularInfo.financingMin, max: data.regularInfo.financingMax})
        }
        if (data.regularInfo.serviceChargeMin && data.regularInfo.serviceChargeMax) {
          data.serviceChargeJson = JSON.stringify({min: data.regularInfo.serviceChargeMin, max: data.regularInfo.serviceChargeMax})
        }
        if (Number(this.attribute.extension) === 1) { data.roll = 'yes' } else { data.roll = 'no' }
        return data
      } else {
        return data
      }
    },
    submitForms () {
      if (!this.multipleSelection || this.multipleSelection.filter(item => item.signingMethod !== '').length === 0) {
        this.$message.error('请选择签署方式')
        return false
      }
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          const selectCity = this.$refs.city.$refs.areaTree.getCheckedNodes()
          const refsTemplate = this.multipleSelection || []
          const data = this._.cloneDeep(this.dataEncapsulation())
          const cityList = []
          let templateList = []
          selectCity.forEach((val, index, arr) => {
            if (val.code) {
              const city = {}
              city.areaCode = val.code
              city.areaName = val.name
              cityList.push(city)
            }
          })
          if (!this._.isEmpty(refsTemplate)) {
            refsTemplate.forEach(item => {
              if (item !== undefined) {
                templateList.push({
                  id: item.id,
                  templateCode: item.contractTemplateNumber,
                  signingMethod: item.signingMethod
                })
              }
            })
          }
          if (cityList.length >= 1) {
            this.loading.submit = true
            const product = {
              info: {
                capName: data.capName,
                capCode: data.capCode,
                capId: data.capId,
                productName: data.productName,
                productFinancing: data.productFinancing,
                modelId: data.modelId,
                productType: data.productType,
                productTypeName: data.productTypeName,
                repaymentMethod: data.repaymentMethod,
                serviceChargeMethod: data.serviceChargeMethod,
                repayment: data.repayment,
                repaymentDay: data.repaymentDay,
                periods: data.periods,
                minCycle: data.minCycleJson,
                interestRate: data.interestRateJson,
                overdue: data.overdueJson,
                maxExtension: data.maxExtensionJson,
                extensionRate: data.extensionRateJson,
                penaltytRate: data.penalTYtRateJson,
                financing: data.financingJson,
                serviceCharge: data.serviceChargeJson,
                maxAmount: data.maxAmount,
                maxPeriod: data.maxPeriod,
                roll: data.roll
              },
              cityList: cityList,
              templateList: templateList
            }
            let api = this.api.product.product.add
            if (this.productCode) {
              product.info.id = data.id
              product.info.productCode = data.productCode
              api = this.api.product.product.update
            }
            api(product).then(result => {
              if (result.data.success === true) {
                this.loading.submit = false
                this.$message.success('操作成功')
                this.$router.back()
              } else {
                this.$message.error(result.data.message)
              }
            }).catch(e => {
              this.loading.submit = false
            })
          } else {
            this.$message.warning('请选择业务覆盖城市')
          }
        } else {
          this.validateFlag = 1
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
